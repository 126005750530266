import React, { useState, useEffect } from "react"
import { Flex, Tabs } from "antd"
import VersionDetails from "./components/VersionDetails"
import VersionProcessesTable from "./components/VersionProcessesTable"
import PropTypes from "prop-types"

const { TabPane } = Tabs

const VersionDetailsTabs = ({ selectedVersionId, activeMainTab }) => {
	const [tab, setTab] = useState("details") // Ensure "details" is the default active tab

	// Reset to "details" tab when activeMainTab changes
	useEffect(() => {
		if (activeMainTab === "versions") {
			setTab("details")
		}
	}, [activeMainTab])

	return (
		<Flex
			vertical
			justify="flex-start"
			align="flex-start"
			gap={"large"}
			style={{ width: "100%", minHeight: "400px", marginTop: "-25px" }}
		>
			<Tabs
				activeKey={tab} // Use activeKey instead of defaultActiveKey
				onChange={setTab}
				type="card"
				size="large"
				style={{ width: "100%" }}
			>
				<TabPane key="details" tab="Details">
					<VersionDetails selectedVersionId={selectedVersionId} />
				</TabPane>

				<TabPane key="processes" tab="Processes">
					<VersionProcessesTable versionId={selectedVersionId} activeTab={tab} />
				</TabPane>
			</Tabs>
		</Flex>
	)
}

VersionDetailsTabs.propTypes = {
	selectedVersionId: PropTypes.string.isRequired,
	activeMainTab: PropTypes.string,
}

export default VersionDetailsTabs
