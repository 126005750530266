import apisauce from "apisauce"

const baseURL = process.env.REACT_APP_API_URL
//const baseURL = `http://localhost:8080/api/`
//const baseURL = `https://flav-cd3-webappdev.azurewebsites.net/api/`
//const baseURL = `https://flav-cd3-webapp1.azurewebsites.net/api/`
// const baseURL =
// 	window.location.hostname === "localhost" ? "http://localhost:3000/api/" : `https://${window.location.hostname}/api/`

const setup = () => {
	const api = apisauce.create({
		baseURL,
		headers: {
			"Cache-Control": "no-cache",
			"Content-Type": "application/json",
		},
		timeout: 90000,
	})

	const naviMonitor = (response) => console.log("========== API Response ==========", response)
	api.addMonitor(naviMonitor)

	const sessionToken = sessionStorage.getItem("accessToken")
	if (sessionToken !== undefined) {
		api.setHeader("Authorization", `Bearer ${sessionToken}`)
	}

	const apiKey = process.env.REACT_APP_API_KEY

	if (apiKey) {
		api.setHeader("x-api-key", apiKey)
	}

	// Access Token
	const setAccessToken = (token) => {
		api.setHeader("Authorization", `Bearer ${token}`)
	}

	const clearAccessToken = () => {
		sessionStorage.removeItem("accessToken")
		api.deleteHeader("Authorization")
	}

	const login = (data) => api.post("users/login", data)
	const verifyToken = (data) => api.post("password/verify", data)
	const verifyInviteToken = (data) => api.post("users/verify-invite", data)
	const updateUserInfo = (data) => api.patch("users", data)
	const getUsers = () => api.get(`users`)
	const getRoles = () => api.get(`users/roles`)
	const updateUser = (id, data) => api.patch(`users/${id}`, data)
	const deleteUser = (id) => api.delete(`users/${id}`)
	const inviteUser = (data) => api.post(`users/invite`, data)
	const reInviteUser = (data) => api.post(`users/reinvite`, data)
	const updatePassword = (id, data) => api.patch(`/password/${id}`, data)
	const resetPassword = (data) => api.post("password/reset", data)
	const addStore = (data) => api.post(`stores`, data)
	const deleteStore = (id) => api.delete(`stores/${id}`)
	const updateStore = (id, data) => api.patch(`stores/${id}`, data)
	const getStore = (filter) => api.get(`stores?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	const getStoreDispensers = (id) => api.get(`stores/${id}/dispensers`)
	const addDispenser = (data) => api.post(`dispensers`, data)
	const deleteDispenser = (id) => api.delete(`dispensers/${id}`)
	const getDispenser = (filter) => api.get(`dispensers?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	const getDispensers = (filter) => api.get(`dispensers?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	const getDispenserLogs = (id, filter) => api.get(`dispensers/${id}/logs`, filter)
	const getLogs = (filter) => api.get(`logs?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	const countLogs = (filter) => api.get(`logs/count?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	const getStoreHierarchy = (hierarchyLevel1) =>
		api.get(
			`stores?filter=%7B%0A%20%20%22fields%22%3A%20%7B%0A%20%20%20%20%22id%22%3A%20true%2C%0A%20%20%20%20%22hierarchyLevel1%22%3A%20true%2C%0A%20%20%20%20%22hierarchyLevel2%22%3A%20true%2C%0A%20%20%20%20%22hierarchyLevel3%22%3A%20true%2C%0A%20%20%20%20%22hierarchyLevel4%22%3A%20true%2C%0A%20%20%20%20%22hierarchyLevel5%22%3A%20true%2C%0A%20%20%20%20%22customer_number%22%3A%20true%2C%0A%20%20%20%20%22location_id%22%3A%20true%2C%0A%20%20%20%20%22store_number%22%3A%20true%2C%0A%20%20%20%20%22store_name%22%3A%20true%0A%20%20%7D%2C%20%0A%20%20%22where%22%3A%20%7B%0A%20%20%20%20%22hierarchyLevel1%22%3A%20%22${hierarchyLevel1}%22%0A%20%20%7D%0A%7D`,
		)
	const getStoreNames = () => api.get("storenames")
	const getFilteredSnapshots = (filter) =>
		api.get(`hierarchysnapshots?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	const getFilteredStores = (filter) => api.get(`stores?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	const getData = (endpoint) => api.get(endpoint)
	const addLog = (id, data) => api.post(`dispensers/${id}/logs`, data)

	const getProcesses = (query) => api.get("/ota-processes", query)
	const getProcessDetails = (id) => api.get(`/ota-processes/details/${id}`)
	const getOTALogs = (id, query) => api.get(`ota-processes/${id}/logs`, query)
	const createProcess = (payload) => api.post(`/ota-processes`, payload)

	const editProcess = (id, payload) => api.patch(`/ota-processes/${id}`, payload)

	const stopProcess = (id) => api.patch(`/ota-processes/${id}/stop`)
	const startProcess = (id) => api.patch(`/ota-processes/${id}/start`)
	const deleteProcess = (id) => api.patch(`/ota-processes/${id}/delete`)

	const getOTAStatistic = (query) => api.get(`ota-statistic`, query)
	const exportOTALogs = (id, query) => api.get(`ota-processes/${id}/logs/export`, query)

	const createVersion = async (payload) => {
		try {
			const { versionName, comments, firmwareData, databaseData } = payload
			const formData = new FormData()

			formData.append(
				"otaVersionData",
				JSON.stringify({
					name: versionName,
					comments,
					firmwareVersion: firmwareData.version,
					databaseVersion: databaseData.version,
				}),
			)

			// const files = firmwareData.files.concat(databaseData.files)

			firmwareData?.files?.map((file) => {
				formData.append("firmwareData", file)
			})

			databaseData?.files?.map((file) => {
				formData.append("databaseData", file)
			})

			return api.post(`/ota-versions`, formData, {
				headers: [
					{
						"Content-Type": "multipart/form-data",
					},
				],
			})
		} catch (error) {
			console.log("error :>> ", error)
		}
	}

	const getVersions = (query) => api.get("/ota-versions", query)
	const getAllVersions = () => api.get("/ota-versions/all")
	const getVersionDetails = (id) => api.get(`/ota-versions/details/${id}`)
	const deleteVersion = (id) => api.delete(`/ota-versions/${id}/delete`)
	const getLastVersion = () => api.get(`/ota-version/latest`)
	const updateVersion = (id, data) => api.patch(`/ota-versions/${id}`, data)

	const streamFilteredSnapshotsData = async (filters) => {
		const url = `${baseURL}hierarchysnapshots/stream?filter=${encodeURIComponent(JSON.stringify(filters))}`

		try {
			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
					"Content-Type": "application/json",
				},
				cache: "no-store", // Prevent caching
			})

			if (!response.body) {
				throw new Error("No response body")
			}

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			const reader = response.body.getReader()
			const decoder = new TextDecoder("utf-8")
			let results = []
			let partialData = "" // Store incomplete JSON

			while (true) {
				const { done, value } = await reader.read() // Read the next chunk
				if (done) {
					break
				}

				const chunk = decoder.decode(value, { stream: true })

				partialData += chunk // Accumulate data

				const lines = partialData.split("\n")
				partialData = lines.pop() // Keep the last incomplete line

				lines.forEach((line) => {
					try {
						const parsedData = JSON.parse(line) // Parse each line
						results.push(parsedData) // Store parsed data
					} catch (err) {
						console.error("Error parsing line:", err, line)
					}
				})
			}

			if (partialData.trim()) {
				try {
					results.push(JSON.parse(partialData)) // Parse the remaining partial data
				} catch (err) {
					console.error("Error parsing remaining data:", err)
				}
			}

			return { data: results }
		} catch (error) {
			console.error("Error fetching or processing data:", error)
			throw error
		}
	}

	const streamStoresData = async (filters) => {
		const url = `${baseURL}stores/stream?filter=${encodeURIComponent(JSON.stringify(filters))}`

		try {
			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
					"Content-Type": "application/json",
				},
			})

			if (!response.body) {
				throw new Error("No response body")
			}

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			const reader = response.body.getReader()
			const decoder = new TextDecoder("utf-8")
			let results = [] // Store parsed results
			let partialData = "" // Store incomplete JSON data

			while (true) {
				const { done, value } = await reader.read()
				if (done) break

				const chunk = decoder.decode(value, { stream: true })
				partialData += chunk // Accumulate chunk data

				const lines = partialData.split("\n")
				partialData = lines.pop() // Keep the last incomplete line for the next iteration

				lines.forEach((line) => {
					try {
						const parsedData = JSON.parse(line) // Parse each line as JSON
						results.push(parsedData)
					} catch (err) {
						console.error("Error parsing JSON line:", err)
					}
				})
			}

			if (partialData.trim()) {
				try {
					results.push(JSON.parse(partialData)) // Parse any remaining partial data
				} catch (err) {
					console.error("Error parsing remaining JSON:", err)
				}
			}

			return { data: results } // Return parsed data
		} catch (error) {
			console.error("Error fetching or processing data:", error)
			throw error
		}
	}

	return {
		getAllVersions,
		updateVersion,
		getVersionDetails,
		deleteVersion,
		getVersions,
		getLastVersion,
		exportOTALogs,
		createVersion,
		getOTAStatistic,
		stopProcess,
		deleteProcess,
		editProcess,
		createProcess,
		getProcessDetails,
		getProcesses,
		startProcess,
		getOTALogs,
		setAccessToken,
		clearAccessToken,
		login,
		addStore,
		deleteStore,
		updateStore,
		getStore,
		getStoreDispensers,
		addDispenser,
		getDispenser,
		getDispensers,
		getDispenserLogs,
		deleteDispenser,
		getLogs,
		countLogs,
		getStoreHierarchy,
		getStoreNames,
		getFilteredStores,
		getFilteredSnapshots,
		getData,
		addLog,
		resetPassword,
		getUsers,
		updateUser,
		updatePassword,
		verifyToken,
		getRoles,
		inviteUser,
		verifyInviteToken,
		updateUserInfo,
		deleteUser,
		reInviteUser,
		streamStoresData,
		streamFilteredSnapshotsData,
	}
}

export default {
	setup,
}
