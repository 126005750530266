import { Card, Flex, Typography, Tabs } from "antd"
import React, { useEffect, useState } from "react"

import {
	ScreenLayout,
	OTAScreenHeader,
	ProcessTable,
	VersionsTable,
	DetailsTabs,
	VersionDetailsTabs,
} from "../components"
import { observer } from "mobx-react"
import { useStore } from "../stores"
import { useOrder } from "../components/shared/hooks/UseOrder"

const { TabPane } = Tabs

const OTAScreen = observer(() => {
	const [order, handleOrder] = useOrder()
	const { dataStore, versionStore } = useStore()
	const [tab, setTab] = useState("processes")

	const handleTabChange = (activeKey) => {
		if (activeKey === "processes") {
			dataStore.setProcessFilters({ offset: 0, page: 5, ...order })
			dataStore.getProcesses()
		}

		setTab(activeKey)
	}

	return (
		<ScreenLayout>
			<Card
				title={<Typography.Title level={4}>OTA Update Management</Typography.Title>}
				style={{
					width: "85vw",
					maxWidth: "1200px",
					margin: "auto",
					marginTop: 40,
					fontSize: "large",
				}}
			>
				<Flex vertical wrap="wrap" justify="space-end" align="flex-end">
					<OTAScreenHeader />
					<Flex
						gap="middle"
						justify="space-between"
						align="flex-start"
						wrap="wrap"
						style={{ width: "100%", marginBottom: "24px" }}
					>
						<Tabs
							onChange={handleTabChange}
							activeKey={tab}
							defaultActiveKey="processes"
							type="card"
							size="large"
							style={{ width: "100%" }}
						>
							<TabPane key="processes" tab="Processes">
								<>
									<ProcessTable />

									{dataStore.selectedProcessId && <DetailsTabs selectedProcessId={dataStore.selectedProcessId} />}
								</>
							</TabPane>

							<TabPane key="versions" tab="Versions">
								<>
									<VersionsTable />

									{versionStore.selectedVersionId && (
										<VersionDetailsTabs selectedVersionId={versionStore.selectedVersionId} activeMainTab={tab} />
									)}
								</>
							</TabPane>
						</Tabs>
					</Flex>
				</Flex>
			</Card>
		</ScreenLayout>
	)
})

export default OTAScreen
